<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">SALDO AWAL EKUITAS</v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3">xxx</v-col>
      </v-row>
      <v-row>
        <v-col cols="3">LABA / (RUGI BERSIH)</v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3">xxx</v-col>
      </v-row>
      <v-row>
        <v-col cols="3">TAMBAHAN MODAL SAHAM</v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3">xxx</v-col>
      </v-row>
      <v-row>
        <v-col cols="3">DIVIDEN </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3">xxx</v-col>
      </v-row>
      <v-row>
        <v-col cols="3">PENAMBAHAN / (PENGURANGAN) EKUITAS</v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3">xxx</v-col>
      </v-row>
      <v-row>
        <v-col cols="3">SALDO AKHIR EKUITAS</v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3">xxx</v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
